/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IonPage, IonHeader, IonToolbar, IonButton, IonIcon, IonContent, IonGrid, IonRow, IonCol, IonItem, IonLabel } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

import { alphabetList } from "../helpers";
import { GameStore, ChallengeStore } from "../stores";
import { PropositionProperty } from "../interfaces";
import { useHistory } from "react-router";
import Timer from "../components/Timer";
import Query from "../components/Query";

import '../theme/Challenge.css'


const Proposal = (props: {letter: string, item: PropositionProperty, index: number, answers: string[]}) => {
    const {ref, label} = props.item
    const [ok, setOk] = React.useState(false)

    React.useEffect(() => {
        if(props.answers.some((o) => o === ref)){
            setOk(true)
        } else {
            setOk(false)
        }
    }, [props.answers])

    return (
        <IonItem style={{width: "100%"}} shape="round" color={ok? "success":"primary"}>
            <IonButton slot="start" shape="round">
                {props.letter}
            </IonButton>
            <IonLabel style={{fontWeight: 'bold'}} className="ion-text-wrap">
                {label}
            </IonLabel>
        </IonItem>
    )
}


const Proposals = (props: {items: PropositionProperty[], answers: string[]}) => {
    const alphabet = alphabetList()

    return (
        <>
            {
                props.items.map((value, index) => (
                    <IonRow key={value.ref} className="ion-margin-vertical">
                        <Proposal item={value} index={index} answers={props.answers} letter={alphabet[index]} />
                    </IonRow>
                ))
            }
        </>
    )
}

const Viewer = () => {
    const challenge = ChallengeStore((state) => state.challenge)
    const [answers, setAnswers] = React.useState<string[]>([])

    React.useEffect(() => {
        setAnswers(challenge?.ok_answers || [])
    }, [challenge])

    return (
        <>
            {
                !challenge ? null : 
                <IonGrid>
                    <IonRow>
                        <IonCol size="8">
                            <IonRow>
                                <IonCol>
                                    <Query 
                                        question={challenge.question} 
                                        level={challenge.level}
                                        expected={challenge.ok_answers.length}
                                        gain={challenge.gain}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    <Proposals items={challenge.proposals} answers={answers} />
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="4">
                            <Timer ttl={challenge.ttl} state={challenge.state} timestamp={challenge.timespan} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            }
        </>
    )
}

const Challenge: React.FC = () => {
    const history = useHistory()
    const gid = GameStore((state) => state.gid)
    

    const quit = () => {
        const url = `/games/${gid}`
        history.push(url)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButton size="small" slot="end" className="ion-margin-end" fill="clear" onClick={quit}>
                        <IonIcon slot="icon-only" icon={closeOutline} color="medium"></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <Viewer />
            </IonContent>
        </IonPage>
    )
}

export default Challenge;
