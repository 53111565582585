/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IonPage, IonHeader, IonToolbar, IonButton, IonIcon, IonContent, IonGrid, IonCol, IonRow, IonItem, IonLabel } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import useAxios from "axios-hooks";
import { useHistory } from "react-router";
import { trophy } from "ionicons/icons";

import { emitter, alphabetList, format } from "../helpers";
import i18n from "../i18n/i18n";
import Timer from "../components/Timer";
import Query from "../components/Query";
import { GameStore, ChallengeStore } from "../stores";
import { CHALLENGE_CLOSED, RESPONSE_SUCCEEDED, WS_EMIT_MESSAGE } from "../constants/config";
import { ParticipantProperty, PropositionProperty } from "../interfaces";
import { ENDPOINTS } from "../constants/api";
import { USER_ACTION_ANSWER_CHOICE } from "../constants/ws_event";

import '../theme/Challenge.css'


const Cross = (props: {row: string, col: string, cid: string}) => {
    const [check, setCheck] = React.useState<boolean>(false)

    const ws_handler = (data: {[key: string]: any}) => {
        const {event, payload} = data;

        if(event === USER_ACTION_ANSWER_CHOICE) {
            if(payload.cid !== props.cid || payload.uid !== props.row || payload.ref !== props.col) return
            setCheck(payload.state)
        }
    }

    React.useEffect(() => {
        emitter.on(WS_EMIT_MESSAGE, ws_handler)
        return () => {
            emitter.off(WS_EMIT_MESSAGE, ws_handler)
        }
    }, [])

    return (
        <>
            {
                !check ? null :
                <IonLabel color={'light'} style={{fontWeight: 'bold'}}>
                    X
                </IonLabel>
            }
        </>
    )
}


const Matrix = (props: {proposals: PropositionProperty[], players: {[key: string]: string}[], cid: string | null, gid: string | null}) => {

    return (
        <IonGrid color="light">
            <IonRow>
                <IonCol></IonCol>
                {props.proposals.map((col) => (
                    <IonCol key={col.ref} className="ion-text-center">
                        <IonButton shape="round">
                            {col.label}
                        </IonButton>
                    </IonCol>
                ))}
            </IonRow>
            {props.players.map((row) => (
                <IonRow key={row.uid}>
                    <IonCol className="ion-text-center">
                        <IonLabel color={'light'}>
                            {row.name}
                        </IonLabel>
                    </IonCol>
                    {props.proposals.map((col) => (
                        <IonCol key={col.ref} className="ion-text-center" style={{ border: '1px solid white' }}>
                            {props.cid !== null && <Cross row={row.uid} col={col.ref} cid={props.cid} />}
                        </IonCol>
                    ))}
                </IonRow>
            ))}
        </IonGrid>
    )
}


const Result = (props: {url: string}) => {
    const [{loading, data}] = useAxios(props.url)
    const [items, setItems] = React.useState<{[key: string]: string | number}[]>([])

    React.useEffect(() => {
        if(data) {
            const selected : {[key: string]: string | number}[]= []
            for (const item of data) {
                if(item.state === RESPONSE_SUCCEEDED) {
                    selected.push(item)
                }
            }
            setItems(selected)
        }
    }, [data])

    return (
        <>
            {
                loading ? null :
                <>
                    {
                        !items.length ? 
                        <IonItem style={{width: "100%"}} shape="round" color={"danger"} className="ion-margin-vertical ion-text-center">
                            <IonLabel style={{fontWeight: 'bold'}} className="ion-text-wrap" color={'light'}>
                                {i18n.t('noSucceedPlayer')}
                            </IonLabel>
                        </IonItem> 
                        :
                        items.map((value, index) => (
                            <IonItem style={{width: "100%"}} shape="round" color={"success"} className="ion-margin-vertical" key={value.uid}>
                                <IonButton slot="start" shape="round">
                                    <IonIcon icon={trophy} color="light"></IonIcon>
                                </IonButton>
                                <IonLabel style={{fontWeight: 'bold'}} className="ion-text-wrap">
                                    {value.name}
                                </IonLabel>
                                <IonButton slot="end" shape="round">
                                    +<span style={{fontSize: "20px"}}>{value.score}</span><span className="ion-text-lowercase">&nbsp; pt(s)</span>
                                </IonButton>
                            </IonItem>
                        ))
                    }
                </>
            }
        </>
    )
}

  

const Viewer = () => {
    const challenge = ChallengeStore((state) => state.challenge)
    const [proposals, setProposals] = React.useState<PropositionProperty[]>([])
    const [participants, setParticipants] = React.useState<{[key: string]: string}[]>([])
    const [url, setURL] = React.useState<string>('')
    const [{data}, call] = useAxios({url: url}, {manual: true})

    const alphabet = alphabetList()

    React.useEffect(() => {
        const proposals = challenge?.proposals || []
        const items: PropositionProperty[] = []
        proposals.map((value, index) => (
            items.push({ref: value.ref, label: alphabet[index] })
        ))
        setProposals(items)

    }, [challenge])

    React.useEffect(() => {
        if(challenge?.cid && challenge?.gid) {
            const u = format(ENDPOINTS.CHALLENGE_PLAYERS, [challenge.gid, challenge.cid])
            setURL(u)
        } else {
            setURL('')
        }
    }, [challenge])

    React.useEffect(() => {
        if(url) call()
    }, [url])

    React.useEffect(() => {
        if(data) {
            const items: {[key: string]: string}[] = []
            data.map((value: ParticipantProperty, _: number) => (
                items.push({uid: value.uid, name: value.name})
            ))
            setParticipants(items)
        }
    }, [data])

    return (
        <>
            {
            !challenge ? null :
                <IonGrid>
                    <IonRow>
                        <IonCol size="8">
                            <IonRow>
                                <IonCol>
                                    <Query 
                                        question={challenge.question} 
                                        level={challenge.level}
                                        expected={challenge.ok_answers.length}
                                        gain={challenge.gain}
                                    />
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol>
                                    {
                                        challenge.state === CHALLENGE_CLOSED ? <Result url={url} /> :
                                            <Matrix cid={challenge.cid} gid={challenge.gid} proposals={proposals} players={participants} />
                                    }
                                </IonCol>
                            </IonRow>
                        </IonCol>
                        <IonCol size="4">
                            <Timer ttl={challenge.ttl} state={challenge.state} timestamp={challenge.timespan} />
                        </IonCol>
                    </IonRow>
                </IonGrid>
            }
        </>
    )
}

const Action: React.FC = () => {
    const history = useHistory()
    const gid = GameStore((state) => state.gid)
   

    const quit = () => {
        const url = `/games/${gid}`
        history.push(url)
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButton size="small" slot="end" className="ion-margin-end" fill="clear" onClick={quit}>
                        <IonIcon slot="icon-only" icon={closeOutline} color="medium"></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className="ion-padding">
                <Viewer />
            </IonContent>
        </IonPage>
    )
}

export default Action;
