import React from "react";
import { IonItem, IonLabel } from "@ionic/react";

import i18n from "../i18n/i18n";

const Query = (props: {question: string | null, level: number | null, expected: number, gain: number | null}) => {
    return (
        <IonItem color={"warning"}>
            <IonLabel className="ion-text-wrap">
                <h1>{props.question}</h1>
                <p>{`Level ${props.level}`} | {`Gain: ${props.gain}`} | {i18n.t('expectedAnswerNum', {num: props.expected})}</p>
            </IonLabel>
        </IonItem>
    )
}

export default Query;