import React, { createContext } from "react";
import ReconnectingWebSocket from 'reconnecting-websocket';

import { GameStore } from "../stores";
import { WS_EMIT_MESSAGE } from "../constants/config";
import { WS_ROOT } from "../constants/api";
import { emitter } from "../helpers";
import { ReactNodeProps } from '../interfaces'


export const WebSocketContext = createContext(null);

export const WebSocketProvider: React.FC<ReactNodeProps> = ({children}) => {
    const socket = React.useRef<ReconnectingWebSocket | null>(null)
    const [url, setURL] = React.useState<string | null>(null)

    const gid = GameStore((state) => state.gid)

    React.useEffect(() => {
        if( gid) {
            const val = `${WS_ROOT}/${gid}/streams/`
            setURL(val)
        } else {
            setURL(null)
        }
    }, [gid])

    React.useEffect(() => {
        if(url && !socket.current) {
            const ws = new ReconnectingWebSocket(url)
            ws.onopen = () => console.log(`listen on ${url}`);
            ws.onmessage = ({data}) => {
                const receive = JSON.parse(data)
                emitter.emit(WS_EMIT_MESSAGE, receive)
            }
            socket.current = ws
        } else if (!url && socket.current) {
            socket.current?.close()
            socket.current = null
        }

        return () => {
            if(url) console.log(`close conn on ${url}`)
            socket.current?.close()
            socket.current = null
        }
    }, [url])


    return (
        <WebSocketContext.Provider value={null}>
            {children}
        </WebSocketContext.Provider>
    )
}