import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { UserState, AppState, GameState, ChallengeState, ParticipantState } from '../interfaces';

export const UserStore = create<UserState>()(
    persist(
      (set, get) => ({
        userToken: null,
  
        // user actions
        getUserToken: () => {
          const t = get().userToken
          return t
        },
        setUserToken: (val) => {
          set({userToken: val})
        },
        resetUser: () => {
          set({userToken: null})
        }
      }),
      {
        name: 'user-store'
      }
    )
)

export const AppStore = create<AppState>()((set, get) => ({
    appName: null,
    appVersion: null,
    online: null,

    // app action
    setAppName: (val) => {
        set({appName: val})
    },
    setAppVersion: (val) => {
        set({appVersion: val})
    },
    setOnline: (val) => {
        set({online: val})
    },
    resetApp: () => {
        set({appName: null, appVersion: null})
    }
}))

export const GameStore = create<GameState>()(
    persist(
        (set, get) => ({
            gid: null,
            title: null,
            category: null,
            start: null,
            end: null,
            is_active: null,

            setGame: (val) => {
              set({
                gid: val.gid,
                title: val.title,
                category: val.category,
                start: val.start,
                end: val.end,
                is_active: val.is_active
              })
            },
            reset: () => {
              set({
                gid: null,
                title: null,
                category: null,
                start: null,
                end: null,
                is_active: null,
              })
            }
        }),
        {name: 'game-store'}
    )
)

export const ChallengeStore = create<ChallengeState>((set, get) => ({
    challenge: null,

    setChallenge: (val) => {
      set({challenge: val})
    },
    updateChallenge: (val) => {
      const v = get().challenge
      set((state) => ({
        challenge: {...v, ...val}
      }))
    },
    getChallenge: () => {
      const v = get().challenge
      return v
    },
    resetChallenge: () => {
      set({challenge: null})
    }
}))


export const ParticipantStore = create<ParticipantState>((set, get) => ({
  participants: [],

  addParticipant: (val) => {
    const v = get().participants
    set((state) => ({
      participants: [...v, val]
    }))
  },
  rmParticipant: (val) => {
    const v = get().participants
    const n = v.filter(function(o) {
      return o.uid !== val
    })
    set((state) => ({
      participants: [...n]
    }))

  },
  updateParticipant: (val) => {
    const v = get().participants
    const i = v.findIndex((o) => {
      return o.uid === val.uid
    })
    if(i !== -1) {
      v[i] = {...v[i], ...val}
    }
    set((state) => ({
      participants: [...v]
    }))
  },
  hasParticipant: (val) => {
    const v = get().participants
    const i = v.findIndex((o) => {
      return o.uid === val
    })
    return i !== -1
  },
  reset: () => {
    set({participants: []})
  }
}))