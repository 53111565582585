/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { 
    IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonGrid, IonRow, IonCol,
    IonButton, IonItem, IonLabel, IonInput, IonText, IonIcon, IonSpinner, IonAlert
} from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import useAxios from 'axios-hooks';
import { lockClosed } from "ionicons/icons";

import { ENDPOINTS } from '../constants/api';
import { AppStore, UserStore } from '../stores';
import i18n from '../i18n/i18n';
import Footer from '../components/Footer';

import LoginBg from '../assets/images/login_bg.png'
import "../theme/Login.css"


const Login: React.FC<RouteComponentProps> = ({history}) => {
    const appName = AppStore((state) => state.appName)

    const {userToken, setUserToken} = UserStore(
        (state) => ({
            userToken: state.userToken,
            setUserToken: state.setUserToken,
        })
    )

    const [username, setUsername] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [iserror, setIserror] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");

    const [{data, loading, error}, call] = useAxios(
        {
            url: ENDPOINTS.AUTH,
            method: 'POST'
        }, {manual: true}
    )

    const login = () => {
        if (!username) {
            setMessage(i18n.t('login.inputs.missingEmail'));
            setIserror(true);
            return;
        }
        if (!password) {
            setMessage(i18n.t('login.inputs.missingPassword'));
            setIserror(true);
            return;
        }
        call({data: {username: username, password: password}})
    }

    React.useEffect(() => {
        if(data) {
            setUserToken(data.token)
        }
    }, [data])

    React.useEffect(() => {
        if(error) {
            setMessage(i18n.t('authError.msg'));
            setIserror(true);
        }
    }, [error])

    React.useEffect(() => {
        if(userToken !== null) history.push('/')
    }, [userToken])

    React.useEffect(() => {
        if(!loading) setPassword('')
    }, [loading])

    return (
        <IonPage id='login'>
            <IonHeader mode='md' translucent>
                <IonToolbar mode='md'>
                    <IonTitle>{appName}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true} className='ion-padding' style={{background: `url(${LoginBg}) no-repeat center center fixed`, backgroundSize: 'cover', height: '100vh'}}>
                <IonGrid fixed id='login'>
                    <IonRow>
                        <IonCol>
                            <IonAlert
                                isOpen={iserror}
                                onDidDismiss={() => {setPassword(''); setIserror(false)}}
                                cssClass="my-custom-class"
                                header={i18n.t('authError.title')}
                                message={message}
                                buttons={["Dismiss"]}
                                mode='md'
                            />
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonRow className='ion-justify-content-center'>
                                <IonIcon
                                    style={{ fontSize: "70px", color: "#ffffff" }}
                                    icon={lockClosed}
                                />
                            </IonRow>
                            
                            <IonText mode='md' className='ion-margin-bottom ion-text-center' style={{color: "white"}}>
                                <h2 style={{marginBottom: 30}}>{i18n.t('login.title')}</h2>
                            </IonText>

                            <IonItem className='ion-margin-bottom' lines='full' mode='md'>
                                <IonLabel position="stacked">{i18n.t('login.inputs.email')}</IonLabel>
                                <IonInput 
                                    required type="email" 
                                    value={username}
                                    placeholder={i18n.t('login.inputs.emailPlaceHolder')} 
                                    onIonChange={(e) => setUsername(e.detail.value!)}
                                />
                            </IonItem>
                            <IonItem className='ion-margin-bottom' lines='full' mode='md'>
                                <IonLabel position="stacked">{i18n.t('login.inputs.password')}</IonLabel>
                                <IonInput
                                    required type='password'
                                    placeholder={i18n.t('login.inputs.passwordPlaceHolder')}
                                    value={password}
                                    onIonChange={(e) => setPassword(e.detail.value!)}
                                />
                            </IonItem>
                            <IonButton expand="block" onClick={login} mode='md' disabled={loading}>
                                { loading && <IonSpinner name="lines" /> }
                                { !loading && i18n.t('signIn')}
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <Footer />

        </IonPage>
    )
}

export default Login;