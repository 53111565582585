import React from "react";
import {
    IonContent, IonPage, IonGrid, IonRow, IonCol, IonLabel, IonButton,
    IonFab, IonIcon, IonFabButton
} from "@ionic/react";
import { refreshOutline } from "ionicons/icons";
import { useHistory } from "react-router";

import { GameStore } from "../stores";
import { emitter } from "../helpers";
import Footer from "../components/Footer";
import i18n from "../i18n/i18n";
import { INTERNAL_EMIT_MESSAGE, INTERNAL_EVENT_REFRESH_ORDERING } from "../constants/config";

import '../theme/Game.css'



const Game: React.FC = () => {

    const history = useHistory()
    const gid = GameStore((state) => state.gid)
    const title = GameStore((state) => state.title)

    const viewChallenge = () => {
        const url = `/games/${gid}/challenge`
        history.push(url)
    }

    const viewScore = () => {
        const url = `/games/${gid}/scores`
        history.push(url)
    }

    const viewAction = () => {
        const url = `/games/${gid}/actions`
        history.push(url)
    }

    const refreshOrdering = () => {
        emitter.emit(INTERNAL_EMIT_MESSAGE, {event: INTERNAL_EVENT_REFRESH_ORDERING, payload: {target: 'score', action: 'reset'}})
        emitter.emit(INTERNAL_EMIT_MESSAGE, {event: INTERNAL_EVENT_REFRESH_ORDERING, payload: {target: 'score', action: 'refresh'}})
    }

    return (
        <IonPage>
            <IonContent fullscreen>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonRow style={{height: "30%"}} className="ion-justify-content-center ion-align-items-center ion-text-center">
                                <IonCol>
                                    <IonLabel class="ion-text-wrap" style={{fontWeight: 'bold', fontSize: 30, color: 'white'}}>
                                        {title}
                                    </IonLabel>
                                </IonCol>
                            </IonRow>
                            <IonRow style={{height: "70%"}}  className="">
                                <IonCol>
                                    <IonButton size="large" className="action-btn" onClick={viewChallenge}>
                                        {i18n.t('challengeView')}
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton size="large" className="action-btn" onClick={viewAction}>
                                        {i18n.t('actionView')}
                                    </IonButton>
                                </IonCol>
                                <IonCol>
                                    <IonButton size="large" className="action-btn" onClick={viewScore}>
                                        {i18n.t('scoreView')}
                                    </IonButton>
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <IonFab slot="fixed" vertical="bottom" horizontal="end">
                    <IonFabButton onClick={() => {refreshOrdering()}}>
                        <IonIcon icon={refreshOutline}></IonIcon>
                    </IonFabButton>
                </IonFab>

            </IonContent>
            <Footer />
        </IonPage>
    )
}

export default Game;
