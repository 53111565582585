const TransLation: {[key: string]: any} = {
    initialization: 'Initialisation',
    signIn: 'Authentification',
    systemError: "Erreur irrécupérable",
    errorContactSupport: "Veuillez contacter l'équipe support",
    login: {
        title: "Formulaire d'authentification",
        inputs: {
            email: "Adresse email",
            emailPlaceHolder: 'Entrez votre adresse email',
            password: "Mot de passe",
            passwordPlaceHolder: "Entrez votre mot de passe",
            missingEmail: 'Veuillez entrer votre email',
            missingPassword: 'Veuillez renseigner votre mot de passe'
        }
    },
    logoutConfirm: {
        title: "Déconnexion",
        msg: "Veuillez confirmer votre demande de déconnexion"
    },
    authError: {
        title: "Erreur d'authentification",
        msg: "Veuillez ré-essayer ou contacter l'équipe support"
    },
    expectedAnswerNum: 'Nombre de bonne réponse attendue: %{num}',
    challengeView: 'Challenge en cours',
    scoreView: 'Score de la partie',
    actionView: 'Action des joueurs',
    offlineDetected: "Perte de connexion",
    dtStart: 'Début',
    dtEnd: 'Fin',
    noSucceedPlayer: "Aucun des joueurs n'a trouvé la bonne réponse"
}

export default TransLation;