/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonRow, IonToolbar } from "@ionic/react";
import { useHistory } from "react-router";
import { closeOutline } from 'ionicons/icons';

import { ParticipantProperty } from '../interfaces'
import { GameStore, ParticipantStore } from "../stores";

import '../theme/Score.css'


const Item = (props: {player: ParticipantProperty, index: number}) => {
    return (
        <IonItem color={'primary'} style={{width: '100%'}}>
            <IonButton shape="round" slot="start">
                <span style={{fontWeight: 'bold', fontSize: 20, margin: 10}}>
                    {props.index + 1}
                </span>
            </IonButton>
            <IonLabel>
                <h1 style={{fontWeight: 'bold'}}>{props.player.name}</h1>
            </IonLabel>
            <IonButton slot="end" fill="clear" color={'light'}>
                <span style={{fontWeight: 'bold', fontSize: 30}}>
                    {props.player.score}
                </span>&nbsp;<span className="ion-text-lowercase">pt(s)</span>
            </IonButton>
        </IonItem>
    )
}

const Score: React.FC = () => {
    const history = useHistory()
    const gid = GameStore((state) => state.gid)
    const participants = ParticipantStore((state) => state.participants)
    const [items, setItems] = React.useState<ParticipantProperty[]>([])

    const quit = () => {
        const url = `/games/${gid}`
        history.push(url)
    }

    React.useEffect(() => {
        const sortedItems = participants.sort((a: ParticipantProperty, b: ParticipantProperty) => (a.score < b.score) ? 1 : -1);
        setItems(sortedItems)
    }, [participants])

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButton size="small" slot="end" className="ion-margin-end" fill="clear" onClick={quit}>
                        <IonIcon slot="icon-only" icon={closeOutline} color="medium"></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonGrid fixed>
                    <IonRow className="ion-justify-content-center ion-align-items-center">
                        <IonCol size="12">
                        {
                            items.map((value, index) => (
                                <IonRow key={index} className="ion-margin-vertical">
                                    <Item player={value} index={index} />
                                </IonRow>
                            ))
                        }
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    )
}

export default Score;
