/* eslint-disable no-empty-pattern */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAxios from "axios-hooks";
import { IonAlert } from "@ionic/react";

import { format, emitter } from "../helpers";
import { UserStore, AppStore, GameStore, ChallengeStore, ParticipantStore } from "../stores";
import { ENDPOINTS } from "../constants/api";
import { WS_EMIT_MESSAGE, INTERNAL_EMIT_MESSAGE, INTERNAL_EVENT_REFRESH_ORDERING } from "../constants/config";
import { CHALLENGE_STATE, CLEAN_BOARD, GAME_PLAYER_CHANGED, GAME_USER_SCORE, NEW_CHALLENGE, SHOW_ANSWER_KEY } from "../constants/ws_event";

export const CheckGame = () => {
    const gid = GameStore((state) => state.gid)
    const resetUser = UserStore((state) => state.resetUser)
    const [url, setURL] = React.useState('')
    const [active, setActive] = React.useState(null)
    const [showAlert, setShowAlert] = React.useState(false)
    const [{data}, call] = useAxios({url: url}, {manual: true})
    const interval = React.useRef<number | null>(null)

    React.useEffect(() => {
        if(gid) {
            const u = format(ENDPOINTS.GAME, [gid])
            setURL(u)
        } else {
            setURL('')
        }
    }, [gid])

    React.useEffect(() => {
        if(url && interval.current === null) {
            interval.current = window.setInterval(() => {
                call()
            }, 10000)
        }

        if(!url && interval.current) {
            window.clearInterval(interval.current)
        }

        return () => {
            if(interval.current) window.clearInterval(interval.current)
        }
    }, [url])

    React.useEffect(() => {
        if(data) {
            setActive(data.is_active)
        }
    }, [data])

    const close = () => {
        setShowAlert(false)
        resetUser()
    }

    return (
        active ? <IonAlert
            isOpen={showAlert}
            onDidDismiss={close}
            header="Alert"
            subHeader="Important message"
            message="This is an alert!"
            buttons={['OK']}
        /> : null
    )

}

export const Ping = () => {
    const userToken = UserStore((state) => state.userToken)

    const [{}, execute] = useAxios(
        {url: ENDPOINTS.PING, method: 'POST'},
        {manual: true}
    )

    const ping = () => {
        if(!userToken) return
        execute()
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            ping()
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    return (
        <></>
    )
}


export const OnLine = () => {
    const setOnline = AppStore((state) => state.setOnline)

    const check = () => {
        if(!navigator.onLine) {
            setOnline(false)
        } else {
            setOnline(true)
        }
    }

    React.useEffect(() => {
        const interval = setInterval(() => {
            check()
        }, 5000);
        return () => clearInterval(interval);
    }, [])

    return (
        <></>
    )
}


export const WatchParticipant = () => {
    const gid = GameStore((state) => state.gid)
    const exists = ParticipantStore((state) => state.hasParticipant)
    const add = ParticipantStore((state) => state.addParticipant)
    const change = ParticipantStore((state) => state.updateParticipant)
    const reset = ParticipantStore((state) => state.reset)

    const [url, setURL] = React.useState<string>('')
    const [{data}, call] = useAxios({url: url}, {manual: true})

    const handler = (data: {[key: string]: any}) => {
        const {event, payload} = data

        if(event === GAME_PLAYER_CHANGED) {
            change(payload)
        }

        if(event === GAME_USER_SCORE) {
            for (const item of payload) {
                change(item)
            }
        }

        if (event === INTERNAL_EVENT_REFRESH_ORDERING) {
            const {target, action} = payload
            if(target === 'score') {
                if(action === 'reset') reset()
                if(action === 'refresh') call()
            }
        }
    }

    React.useEffect(() => {
        emitter.on(WS_EMIT_MESSAGE, handler)
        return () => {
            emitter.off(WS_EMIT_MESSAGE, handler)
        }
    }, [])

    React.useEffect(() => {
        emitter.on(INTERNAL_EMIT_MESSAGE, handler)
        return () => {
            emitter.off(INTERNAL_EMIT_MESSAGE, handler)
        }
    })

    React.useEffect(() => {
        reset()

        if(gid) {
            const u = format(ENDPOINTS.GAME_PLAYERS, [gid])
            setURL(u)
        }
    }, [gid])

    React.useEffect(() => {
        if(url) {
            call()
        }
    }, [url])

    React.useEffect(() => {
        if(data) {
            for (const item of data) {
                if(!exists(item.uid)) {
                    add(item)
                } else {
                    change(item)
                }
            }
        }
    }, [data])

    return (
        <></>
    )
}


export const WatchChallenge = () => {
    const gid = GameStore((state) => state.gid)
    const getChallenge = ChallengeStore((state) => state.getChallenge)
    const setChallenge = ChallengeStore((state) => state.setChallenge)
    const updateChallenge = ChallengeStore((state) => state.updateChallenge)
    const resetChallenge = ChallengeStore((state) => state.resetChallenge)

    const handler = (data: {[key: string]: any}) => {
        const {event, payload} = data

        if(event === NEW_CHALLENGE) {
            const ch = {
                gid: payload.gid,
                cid: payload.cid,
                level: payload.level,
                gain: payload.gain,
                state: payload.state,
                question: payload.question,
                tags: payload.tags || [],
                timespan: payload.timespan,
                ttl: payload.ttl,
                proposals: payload.proposals,
                ok_answers: payload.ok_answers
            }
            setChallenge(ch)
        }
        
        if(event === CLEAN_BOARD) {
            resetChallenge()
        }
        
        if(event === CHALLENGE_STATE) {
            const ch = getChallenge()
            if(ch && ch.cid === payload.cid) {
                ch.state = payload.state
                updateChallenge(ch)
            }
        }

        if(event === SHOW_ANSWER_KEY) {
            const ch = getChallenge()
            if(ch && ch.cid === payload.cid) {
                ch.ok_answers = payload.answers
                updateChallenge(ch)
            }
        }
    }

    React.useEffect(() => {
        emitter.on(WS_EMIT_MESSAGE, handler)
        return () => {
            emitter.off(WS_EMIT_MESSAGE, handler)
        }
    }, [])

    React.useEffect(() => {
        resetChallenge()
    }, [gid])

    return (
        <></>
    )
}