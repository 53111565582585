/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { IonContent, IonPage } from '@ionic/react';

import Footer from '../components/Footer';

import '../theme/Home.css';
import GameList from '../components/GameList';
import { GameStore } from '../stores';

const Home: React.FC = () => {
  const resetGame = GameStore((state) => state.reset)

  React.useEffect(() => {
    resetGame()
  }, [])

  return (
    <IonPage>
      <IonContent fullscreen>
        <GameList />
      </IonContent>
      <Footer />
    </IonPage>
  );
};

export default Home;
