export const APP_CATEGORY: string = 'core.plugins.reqrep'

export const RESPONSE_PENDING: string = 'pending'
export const RESPONSE_SUBMIT: string = 'submit'
export const RESPONSE_OUTDATED: string = 'outdated'
export const RESPONSE_SKIPPED: string = 'skipped'
export const RESPONSE_SUCCEEDED: string = 'succeeded'
export const RESPONSE_FAILED: string = 'failed'

export const CHALLENGE_PENDING: string = 'pending'
export const CHALLENGE_OPENED: string = 'opened'
export const CHALLENGE_CLOSED: string = 'closed'

export const INTERNAL_EVENT_REFRESH_ORDERING: string = 'internal_event_refresh_ordering'

export const WS_EMIT_MESSAGE: string = "ws_emit_message"
export const HTTP_EMIT_MESSAGE: string = "http_emit_message"
export const INTERNAL_EMIT_MESSAGE: string = "internal_emit_message"