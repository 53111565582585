/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import useAxios from "axios-hooks";
import {
    IonGrid, IonRow, IonCol, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
    IonFab, IonIcon, IonFabButton
} from '@ionic/react';
import { useHistory } from 'react-router';
import { refreshOutline } from "ionicons/icons";

import { groupedData } from "../helpers";
import { GameStore } from "../stores";
import { GameState } from "../interfaces";
import i18n from "../i18n/i18n";
import { ENDPOINTS } from "../constants/api";
import { APP_CATEGORY } from "../constants/config";


const GameCard = (props: {item: GameState}) => {
    const history = useHistory();
    const [color, setColor] = React.useState('')
    const setGame = GameStore((state) => state.setGame)

    React.useEffect(() => {
        setColor(props.item.is_active ? 'primary':'danger')
    }, [])

    const call = () => {
        if (!props.item) return
        setGame(props.item)
        const url = `/games/${props.item.gid}`
        history.push(url)
    }

    return (
        <IonCard color={color} onClick={call}>
            <IonCardHeader>
                <IonCardTitle>{props.item.title}</IonCardTitle>
                <IonCardSubtitle style={{fontSize: 12}}></IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
                <div>{i18n.t('dtStart')}: {props.item.start}</div>
                <div>{i18n.t('dtEnd')}: {props.item.end}</div>
            </IonCardContent>
        </IonCard>
    )
}


const GameRow = (props: {items: [GameState]}) => {
    return (
        <IonRow>
            {
                props.items.map((item, index) => (
                    <IonCol key={`col-${item.gid}`} size="4">
                        <GameCard item={item} />
                    </IonCol>
                ))
            }
        </IonRow>
    )
}


const GameList = () => {
    const [items, setItems] = React.useState([])

    const [{loading, data, error}, execute] = useAxios(
        {url: `${ENDPOINTS.GAME_LIST}?category=${APP_CATEGORY}&page_size=0`}, {manual: true}
    )

    React.useEffect(() => {
    }, [error])

    React.useEffect(() => {
        execute()
    }, [])

    React.useEffect(() => {
        const results = data?.results
        if(results) {
            const grouped = groupedData(results, 3)
            setItems(grouped)
        }
    }, [data])

    if(loading) return null


    return (
        <>
            <IonFab slot="fixed" vertical="bottom" horizontal="end">
                <IonFabButton onClick={() => {execute()}}>
                    <IonIcon icon={refreshOutline}></IonIcon>
                </IonFabButton>
            </IonFab>

            <IonGrid>
                {
                    items.map((values: [any], index: number) => (
                        <GameRow items={values} key={`row-${index}-${values.length}`} />
                    ))
                }
            </IonGrid>
        </>
    )
}


export default GameList;