
export const API_ROOT: string = process.env.NODE_ENV === 'development' ? 'http://api.eqsass.local:8000' : 'https://api.eqsass.com'
export const HEADER_PREFIX: string = "Token"
export const VERSION: string = "1.0"
export const ENDPOINTS: {[key: string]: string} = {
    APP_CONF: '/app-conf/',
    GAME_LIST: '/games/',
    GAME: '/games/{0}/',
    GAME_PLAYERS: '/games/{0}/participants/',
    CHALLENGE_PLAYERS: '/games/{0}/challenges/{1}/players/',
    CHALLENGE_ANSWER: '/games/{0}/challenges/{1}/answers/',
    RANKING: '/games/{0}/ranking/',
    PING: '/users/ping/',
    AUTH: '/users/auth/'
}

export const WS_ROOT: string = process.env.NODE_ENV === 'development' ? 'ws://localhost:8000' : 'wss://api.eqsass.com'