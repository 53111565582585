import EventEmitter from 'events';

export const emitter = new EventEmitter();

export const format = function(str: any, data: string[]) {
  return str.replace(/{([^{}]+)}/g, function(match: boolean, val: any) {
    var prop: any = data;
    val.split('.').forEach(function(key: number) {
      prop = prop[key];
    });

    return prop;
  });
};


export const groupedData = (items: [any], by: number) => {
  const ret = items.reduce((acc: [any], curr: number, i: number): [any] => {
      const index = Math.floor(i / by)
      if(!acc[index]) {
          acc[index] = []
      }
      acc[index].push(curr)
      return acc
  }, [])
  return ret
}

export const alphabetList = () => {
  let alphabet = [];
  for (let i = 65; i <= 90; i++) {
    alphabet.push(String.fromCharCode(i));
  }
  return alphabet
}