import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import Home from './pages/Home';
import Login from './pages/Login';
import Game from './pages/Game';
import Challenge from './pages/Challenge';
import Score from './pages/Score';
import Action from './pages/Action';

import { UserStore } from './stores';

import { OnLine, Ping, WatchChallenge, WatchParticipant } from './components/Monitor';
import { LoadAppConf } from './components/LoadAppConf';
import { AxiosProvider } from './providers/axios';
import { WebSocketProvider } from './providers/websocket';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/App.css'


setupIonicReact();

const AppRoutes: React.FC = () => {
  const userToken = UserStore((state) => state.userToken)

  return (
    <IonRouterOutlet id="main">
      <Route exact path="/login" component={Login} />
      <Route
        path="/"
        exact
        render={() => (userToken ? <Home /> : <Redirect to="/login" />)}
      />
      <Route
        path="/games/:gid"
        exact
        render={() => (userToken ? <Game /> : <Redirect to="/login" />)}
      />
      <Route
        path="/games/:gid/scores"
        exact
        render={() => (userToken ? <Score /> : <Redirect to="/login" />)}
      />
      <Route
        path="/games/:gid/challenge"
        exact
        render={() => (userToken ? <Challenge /> : <Redirect to="/login" />)}
      />
      <Route
        path="/games/:gid/actions"
        exact
        render={() => (userToken ? <Action /> : <Redirect to="/login" />)}
      />
      <Route
        render={() => (<Redirect to="/" />)}
      />
    </IonRouterOutlet>
  )
}

const App: React.FC = () => {

  const userToken = UserStore((state) => state.userToken)

  return (
    <IonApp style={{ backgroundColor: '#171031' }}>
      <AxiosProvider>
        <WebSocketProvider>
          <LoadAppConf />
          <IonReactRouter>
            <AppRoutes />
          </IonReactRouter>
          {userToken ? <Ping /> : null}
          <OnLine />
          <WatchChallenge />
          <WatchParticipant />
        </WebSocketProvider>
      </AxiosProvider>
    </IonApp>
  )
};

export default App;
