/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import dayjs from 'dayjs';

import { CHALLENGE_CLOSED } from "../constants/config";


const Timer = (props: {ttl: string | null, state: string | null, timestamp: number | null}) => {
    const { ttl , state, timestamp } = props
    const [value, setValue] = React.useState<number | null>(timestamp)
    const [color, setColor] = React.useState<string>('white')
    const interval = React.useRef<number | null>(null)

    React.useEffect(() => {
        if(ttl) {
            interval.current = window.setInterval(() => {
                const _ttl = new Date(ttl)
                const _now = new Date()
                const now = dayjs(_now)
                const l_ttl = dayjs(_ttl)
                const diff = l_ttl.diff(now, 's') // seconds
                setValue(diff)
            }, 1000)
        }

        return () => {
            if(interval.current) window.clearInterval(interval.current)
        }
    }, [ttl])

    React.useEffect(() => {
        if(value !== null) {
            if(value > 10) {
                setColor('white')
            } else {
                setColor('red')
            }
        }
    }, [value])

    React.useEffect(() => {
        if(state === CHALLENGE_CLOSED) {
            if(interval.current) {
                window.clearInterval(interval.current)
            }
        }
    }, [state])

    return (
        <p className="ion-text-center" style={{color: color, fontSize: 170, fontWeight: 'bold'}}>
            {
                value === null ? '-': <>
                    {
                        value < 0 ? 0 : value
                    }
                </>
            }
        </p>
    )

}

export default Timer;